<template>
  <div class="gray-modern-panel compact-panel">
    <div class="small-bold-header bottom-10">
      Recent Activity
    </div>

    <div class="text-italic">
      <div v-if="isSelected">
        {{ lawFirmName || lawyerFirstName }} was hired.
      </div>

      <div v-else-if="isCurrentClientInteracting">
        <span v-if="!hasAppointment && !hasMessage && !hasProposal">No activity yet.</span>

        <span v-if="hasMessage">Message sent to {{ lawyerFirstName }}.</span>

        <span v-if="hasProposedApptTimes">You proposed times for a {{ appointmentType }} and are waiting for {{ lawyerFirstName }} to confirm availability.</span>

        <span v-if="needsNewApptTimes">{{ lawyerFirstName }} has indicated that the availabilities you sent do not work for them. Please update your availability.</span>

        <span v-if="hasAlternativeApptTimes">{{ lawyerFirstName }} has suggested alternative times for your {{ appointmentType }}. Please <button type="button" class="pseudo-link-blue" @click="confirmAppointment">choose from the suggested times.</button></span>

        <span v-if="hasScheduledAppt">You have scheduled your {{ appointmentType }} with {{ lawyerFirstName }} for {{ appointmentStart }}. If this time no longer works for you, you may <button type="button" class="pseudo-link-blue" @click="cancelAppointment">cancel</button> or <button type="button" class="pseudo-link-blue" @click="rescheduleAppointment">reschedule</button>.</span>

        <span v-if="needsNewAvailability">Please update your availability so that you may get in touch with {{ lawyerFirstName }}.</span>

        <span v-if="needsRescheduling">Your {{ appointmentType }} with {{ lawyerFirstName }} needs to be rescheduled.</span>

        <span v-if="isApptCancelled">Your {{ appointmentType }} with {{ lawyerFirstName }} has been cancelled.</span>

        <span v-if="isApptCompleted && !hasProposal">You had your {{ appointmentType }} with {{ lawyerFirstName }} and are waiting for a proposal.</span>

        <span v-if="hasProposal">{{ lawyerFirstName }} sent you a proposal.</span>

        <span v-if="hasPaymentIssue">You attempted to hire {{ lawyerFirstName }}, but there was an issue with your initial payment.</span>
      </div>

      <div v-else>
        <span v-if="!appointment && !hasMessage && !hasProposal && !consultationCompletedOutside">No activity yet.</span>

        <span v-if="hasMessage">Message sent to {{ lawyerFirstName }}.</span>

        <span v-if="isSchedulingAppt">{{ interactingClientName }} is currently scheduling a {{ appointmentType }} with {{ lawyerFirstName }}.</span>

        <span v-if="hasScheduledAppt">{{ interactingClientName }} has scheduled a {{ appointmentType }} with {{ lawyerFirstName }} for {{ appointmentStart }}.</span>

        <span v-if="isApptCancelled">{{ interactingClientName }}&rsquo;s {{ appointmentType }} with {{ lawyerFirstName }} has been cancelled.</span>

        <span v-if="isApptCompleted && !hasProposal">{{ interactingClientName }} has completed a {{ appointmentType }} with {{ lawyerFirstName }} and is waiting for a proposal.</span>

        <span v-if="consultationCompletedOutside">Waiting for a proposal from {{ lawyerFirstName }}.</span>

        <span v-if="hasProposal">{{ lawyerFirstName }} sent a proposal.</span>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'RecentActivity',

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    onConfirmAppointment: {
      type: Function,
      required: true
    },

    onRescheduleAppointment: {
      type: Function,
      required: true
    },

    onCancelAppointment: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    lawFirmName() {
      return this.possibleLawyer.lawFirm?.name;
    },

    lawyerFirstName() {
      return this.possibleLawyer.lawyer.firstName;
    },

    appointment() {
      return this.possibleLawyer.appointment;
    },

    appointmentType() {
      return this.appointment?.type === 'in-person' ? 'meeting' : 'call';
    },

    appointmentStatus() {
      return this.appointment?.status();
    },

    appointmentStart() {
      return DateFilter.longWithAt(this.appointment.scheduledConsultation().startsAt);
    },

    hasAppointment() {
      return !!this.appointment;
    },

    hasMessage() {
      return this.possibleLawyer.messagedStatus();
    },

    hasProposedApptTimes() {
      return this.appointmentStatus === 'requested';
    },

    needsNewApptTimes() {
      return this.possibleLawyer.needsNewTimes();
    },

    hasAlternativeApptTimes() {
      return this.appointmentStatus === 'alternatives_suggested';
    },

    hasScheduledAppt() {
      return this.appointmentStatus === 'scheduled';
    },

    needsNewAvailability() {
      return this.appointmentStatus === 'needs_new_availability';
    },

    needsRescheduling() {
      return this.appointmentStatus === 'needs_rescheduling';
    },

    isSchedulingAppt() {
      return this.appointment?.isScheduling();
    },

    isApptCancelled() {
      return this.appointmentStatus === 'cancelled';
    },

    isApptCompleted() {
      return this.appointmentStatus === 'completed';
    },

    consultationCompletedOutside() {
      return this.possibleLawyer.consultationCompletedOutside();
    },

    hasProposal() {
      return this.possibleLawyer.rateSentStatus();
    },

    hasPaymentIssue() {
      return this.possibleLawyer.clientAcceptedStatus();
    },

    isSelected() {
      return this.possibleLawyer.selected();
    },

    interactingClientName() {
      return this.possibleLawyer.interactingClient().fullName;
    },

    isCurrentClientInteracting() {
      return this.user && this.possibleLawyer.isCurrentClientInteracting(this.user);
    }
  },

  methods: {
    confirmAppointment() {
      if (!this.hasAlternativeApptTimes) { return; }
      this.onConfirmAppointment();
    },

    rescheduleAppointment() {
      if (!this.hasScheduledAppt) { return; }
      this.onRescheduleAppointment();
    },

    cancelAppointment() {
      if (!this.hasScheduledAppt) { return; }
      this.onCancelAppointment();
    }
  }
};
</script>
