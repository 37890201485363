<template>
  <div>
    <slot name="text" :rendering-text="renderingText">
      <div class="rendering-text">
        <span class="simple-text">{{ renderingText }}</span>
      </div>
    </slot>

    <slot name="viewMoreButton" :view-more="viewMore" :toggle-view-more="toggleViewMore">
      <button type="button" class="button dark-gray-link size-text-12px" @click="toggleViewMore" v-if="textLength > charLimit">{{ viewMoreOrLess }}</button>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'CollapsableText',

  props: {
    charLimit: {
      type:     Number,
      default:  750
    },

    text: {
      type:     String,
      required: true
    }
  },

  data() {
    return {
      viewMore: false
    };
  },

  computed: {
    textLength() {
      return this.text.length;
    },

    renderingText() {
      if (this.viewMore || this.textLength <= this.charLimit) {
        return this.text;
      }
      else {
        // shortened text
        let subText = this.text.slice(0, this.charLimit);
        subText = subText.substr(0, Math.min(subText.length, subText.lastIndexOf(' ')));

        return `${subText}...`;
      }
    },

    viewMoreOrLess() {
      return this.viewMore ? 'View less' : 'View more';
    }
  },

  methods: {
    toggleViewMore() {
      this.viewMore = !this.viewMore;
    }
  }
};
</script>

<style lang="scss" scoped>
  .rendering-text {
    line-height: 1.4;
  }

  .button {
    background: transparent;
    border: 0;
    padding: 0;
  }
</style>
