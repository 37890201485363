<template>
  <div class="row text-center">
    <div class="col-sm-3 next-steps">
      <div class="next-steps-separator"></div>

      <div class="next-steps-content">
        <div class="center-block">
          <div class="bottom-15">
            <svg-icon name="invite-gradient" class="base-icon"></svg-icon>
          </div>

          <div class="bold-weight bottom-10 text-uppercase">
            Add Team Members
          </div>
        </div>

        <div class="block size-text-13px">
          Add team members to the project so that they can view the suggested options.
        </div>
      </div>
    </div>

    <div class="col-sm-3 next-steps">
      <div class="next-steps-separator"></div>

      <div class="next-steps-content">
        <div class="center-block">
          <div class="bottom-15">
            <svg-icon name="calendar-gradient" class="base-icon"></svg-icon>
          </div>

          <div class="bold-weight bottom-10 text-uppercase">
            Schedule Screening Calls
          </div>
        </div>

        <div class="block size-text-13px">
          Message or schedule screening calls with your preferred options to discuss your project and determine the best fit for the work.
        </div>
      </div>
    </div>

    <div class="col-sm-3 next-steps">
      <div class="next-steps-separator"></div>

      <div class="next-steps-content">
        <div class="center-block">
          <div class="bottom-15">
            <svg-icon name="request-gradient" class="base-icon"></svg-icon>
          </div>

          <div class="bold-weight bottom-10 text-uppercase">
            Request Formal Proposals
          </div>
        </div>

        <div class="block size-text-13px">
          Request a formal proposal. You will be notified by email when the proposal is ready.
        </div>
      </div>
    </div>

    <div class="col-sm-3 next-steps">
      <div class="next-steps-content">
        <div class="center-block">
          <div class="bottom-15">
            <svg-icon name="added-item-gradient" class="base-icon"></svg-icon>
          </div>

          <div class="bold-weight bottom-10 text-uppercase">
            Hire Your Provider
          </div>
        </div>

        <div class="block size-text-13px">
          Review and accept your preferred formal proposal. The provider you selected will be notified and will contact you to start work.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'NextSteps',

  components: {
    SvgIcon
  },

  props: {
    proposal: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .next-steps {
    position: relative;
    margin-bottom: 85px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .next-steps-content {
      padding: 0 5px;

      .base-icon {
        width: 53px;
        height: 53px;
      }
    }

    .next-steps-separator {
      position: absolute;
      height: 55px;
      top: 110%;
      left: 50%;
      margin-left: -1px;
      border-left: 3px dotted $k-gray;

      @media (min-width: $screen-sm-min) {
        width: 61%;
        height: unset;
        top: 25px;
        left: 70%;
        margin-left: 0;
        border-top: 3px dotted $k-gray;
        border-left: 0;
      }

      @media (min-width: $screen-lg-min) {
        width: 70%;
        left: 65%;
      }
    }
  }
</style>
