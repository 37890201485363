<template>
  <section class="featured-lawyers">
    <div v-for="featuredLawyer in sortedFeaturedLawyers" :key="featuredLawyer.id">
      <featured-lawyer :featured-lawyer="featuredLawyer"></featured-lawyer>
    </div>
  </section>
</template>

<script>
import FeaturedLawyer from 'vue-app/marketplace/client/proposals/featured-lawyer.vue';
import { sortBy } from 'lodash';

export default {
  name: 'FeaturedLawyers',

  components: {
    FeaturedLawyer
  },

  props: {
    featuredLawyers: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    sortedFeaturedLawyers() {
      return sortBy(this.featuredLawyers, (fl) => (fl.featurable.fullName));
    }
  }
};
</script>
