<!-- TODO: fix bottom margins -->
<template>
  <div class="proposal-lawyer-details row size-text-13px">
    <div :class="['main-details col-xs-12', { 'col-sm-7 right-padded': !lawFirm }]">
      <div class="row">
        <div :class="['col-xs-12', { 'col-sm-8': isLawFirm && insideTheGate }]">
          <div class="bottom-30">
            <div v-if="sectionHasData('proposal_bio')">
              <div class="small-bold-header bottom-10">
                About
              </div>

              <collapsable-text :text="possibleLawyer.proposalBio" :char-limit="200"></collapsable-text>
            </div>

            <div v-if="insideTheGate || shouldShowSection('resume')" :class="{ 'top-15': sectionHasData('proposal_bio') }">
              <router-link :to="`/law-firms/${lawFirm.id}/requests/${possibleLawyer.id}`" v-if="lawFirm && insideTheGate && !isMarketplaceRfq">View {{ isAlsp ? 'Company' : 'Firm' }} Profile</router-link>
              <router-link :to="`/requests/profile/${possibleLawyer.id}`" v-if="!lawFirm && insideTheGate && !isMarketplaceRfq">View Full Profile</router-link>

              <span v-if="sectionHasData('resume')">
                <span v-if="insideTheGate" class="left-5 right-5 bold-weight">&#8231;</span>

                <a :href="possibleLawyer.resume.url" class="dark-gray-link" target="_blank">View Resume</a>
              </span>
            </div>
          </div>

          <div class="top-30" v-if="isAlsp && alspTechnologyTypes && alspTechnologyTypes.length">
            <div class="small-bold-header bottom-10">
              Technology Types
            </div>

            <div class="tag-list">
              <div class="tag-list-item gray compact" v-for="technology in alspTechnologyTypes" :key="technology">
                {{ technology }}
              </div>
            </div>
          </div>

          <div class="top-30" v-if="isAlsp && alspSecurityCertifications && alspSecurityCertifications.length">
            <div class="small-bold-header bottom-10">
              Security Certifications
            </div>

            <div class="tag-list">
              <div class="tag-list-item gray compact" v-for="certification in alspSecurityCertifications" :key="certification">
                {{ certification }}
              </div>
            </div>
          </div>

          <div class="top-30" v-if="shouldShowSection('notables') && !lawFirm">
            <div class="tag-list">
              <div class="tag-list-item gray compact" v-for="experienceName in possibleLawyer.notableExperiences" :key="experienceName">
                {{ experienceName }}
              </div>
            </div>
          </div>
        </div>

        <div :class="['col-xs-12 top-30', { 'col-sm-4': isLawFirm && insideTheGate }]">
          <div class="bottom-30" v-if="showProposalQuote || showFirmRate">
            <div class="small-bold-header bottom-10">
              {{ pricingTitle }}
            </div>

            <div>
              <div class="lawyer-details-sidebar-item">
                <div v-if="isLawFirm && insideTheGate">
                  <svg-icon name="rate" class="base-icon"></svg-icon>
                </div>
                <span v-if="showProposalQuote" class="simple-text">{{ possibleLawyer.proposalQuote }}</span>
                <span v-if="showFirmRate" class="simple-text">{{ possibleLawyer.firmRate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row bottom-30" v-if="insideTheGate && !isMarketplaceRfq">
        <div :class="['main-details col-xs-12', { 'col-sm-7 right-padded': lawFirm }]">
          <recent-activity
            :possible-lawyer="possibleLawyer"
            :on-confirm-appointment="onConfirmAppointment"
            :on-reschedule-appointment="onRescheduleAppointment"
            :on-cancel-appointment="onCancelAppointment">
          </recent-activity>
        </div>
      </div>

      <div class="bottom-30" v-if="shouldShowSection('firm_featured_lawyers')">
        <div class="small-bold-header bottom-10">
          {{ featuredLawyersTitle }}
        </div>

        <hr class="kenny-hr no-margin">

        <featured-lawyers :featured-lawyers="possibleLawyer.featuredLawyers"></featured-lawyers>
      </div>

      <project-manager-note :class="{ 'hidden-xs': !lawFirm }" :possible-lawyer="possibleLawyer"></project-manager-note>
    </div>

    <div class="col-xs-12 col-sm-5 lawyer-details-sidebar-container" v-if="hasDisplaySections && !lawFirm">
      <div class="lawyer-details-sidebar">
        <div class="lawyer-details-sidebar-item" v-if="canShowSection('location') && location">
          <div>
            <svg-icon name="location" class="base-icon"></svg-icon>
          </div>

          <div class="text-ellipsis">
            <div class="semibold-weight bottom-5">
              Location
            </div>

            <div class="text-ellipsis">
              {{ location }}
            </div>
          </div>
        </div>

        <div class="lawyer-details-sidebar-item" v-if="canShowSection('rates') && !isMarketplaceRfq && !possibleLawyer.rateSentStatus() && profileRate.dollars">
          <div>
            <svg-icon name="rate" class="base-icon"></svg-icon>
          </div>

          <div>
            <div class="semibold-weight bottom-5">
              Rate
            </div>

            <div>
              {{ profileRateDollars }}{{ profileRate.tag() }}
            </div>
          </div>
        </div>

        <div class="lawyer-details-sidebar-item" v-if="shouldShowSection('experience') && possibleLawyer.yearsExperience > 0">
          <div>
            <svg-icon name="experience" class="base-icon"></svg-icon>
          </div>

          <div>
            <div class="semibold-weight bottom-5">
              Experience
            </div>

            <div>
              {{ possibleLawyer.yearsExperience }} Years
            </div>
          </div>
        </div>

        <div class="lawyer-details-sidebar-item" v-if="shouldShowSection('bars') || shouldShowSection('courts')">
          <div>
            <svg-icon name="ribbon" class="base-icon"></svg-icon>
          </div>

          <div>
            <div class="semibold-weight bottom-5">
              Admissions
            </div>

            <div v-if="shouldShowSection('bars')" :class="{ 'bottom-10': shouldShowSection('courts') }">
              <div class="dark-gray-text size-text-12px text-uppercase">
                Bar
              </div>

              <div v-for="bar in possibleLawyer.barAdmissions" :key="bar.barId">
                {{ barName(bar) }}, {{ parseDate(bar.admitDate) }}
              </div>
            </div>

            <div v-if="shouldShowSection('courts')">
              <div class="dark-gray-text size-text-12px text-uppercase">
                Court
              </div>

              <div v-for="court in courtAdmissions" :key="court.courtId">
                {{ courtName(court.courtId) }}, {{ parseDate(court.admitDate) }}
              </div>
            </div>
          </div>
        </div>

        <div class="lawyer-details-sidebar-item" v-if="shouldShowSection('languages')">
          <div>
            <svg-icon name="language" class="base-icon"></svg-icon>
          </div>

          <div>
            <div class="semibold-weight bottom-5">
              Languages
            </div>

            <div v-for="(lang, index) in possibleLawyer.languages" :key="index">
              {{ lang.name }}
            </div>
          </div>
        </div>

        <div class="lawyer-details-sidebar-item" v-if="shouldShowSection('education')">
          <div>
            <svg-icon name="education" class="base-icon"></svg-icon>
          </div>

          <div>
            <div class="semibold-weight bottom-5">
              Education
            </div>

            <div v-for="(school, index) in possibleLawyer.schools" :key="index">
              {{ school.name }}, {{ school.degree }}
            </div>
          </div>
        </div>

        <div class="lawyer-details-sidebar-item" v-if="shouldShowSection('malpractice_insurance')">
          <div>
            <svg-icon name="documents" class="base-icon"></svg-icon>
          </div>

          <div>
            <div class="semibold-weight bottom-5">
              Malpractice Insurance
            </div>

            <div>
              {{ malpracticeInsurancePresenceCopy }}
            </div>
          </div>
        </div>
      </div>

      <project-manager-note class="top-30 hidden-sm hidden-md hidden-lg" :possible-lawyer="possibleLawyer"></project-manager-note>
    </div>
  </div>
</template>

<script>
import FeaturedLawyers from 'vue-app/marketplace/client/proposals/featured-lawyers.vue';
import RecentActivity from 'vue-app/marketplace/client/proposals/recent-activity.vue';
import ProjectManagerNote from 'vue-app/marketplace/client/proposals/project-manager-note.vue';
import CollapsableText from 'vue-app/shared/components/collapsable-text.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import SharedListsService from 'vue-app/shared/services/shared-lists-service';
import DateFilter from 'vue-app/shared/lib/date-filter';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter';

import { isEmpty, isBoolean, get, isNumber } from 'lodash';

export default {
  name: 'ProposalLawyerDetails',

  components: {
    FeaturedLawyers,
    RecentActivity,
    ProjectManagerNote,
    CollapsableText,
    SvgIcon
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    insideTheGate: {
      type: Boolean,
      default: false
    },

    onConfirmAppointment: {
      type: Function,
      required: false
    },

    onRescheduleAppointment: {
      type: Function,
      required: false
    },

    onCancelAppointment: {
      type: Function,
      required: false
    },

    isMarketplaceRfq: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    displaySections() {
      return this.possibleLawyer.displaySections || [];
    },

    profileRate() {
      return this.possibleLawyer.visibleRate();
    },

    profileRateDollars() {
      return CurrencyFilter.filter(this.profileRate.dollars);
    },

    location() {
      const address = this.possibleLawyer.lawyer.address;
      const { country, city, state } = address;

      if (!address || !country) { return null; }

      if (country === 'United States' && city && state) {
        return `${city}, ${SharedListsService.stateAbbreviationFromName(state)}`;
      }
      else {
        return country;
      }
    },

    hasDisplaySections() {
      return !!this.displaySections.length;
    },

    lawFirmName() {
      return this.lawFirm?.name;
    },

    lawFirm() {
      return this.possibleLawyer.lawFirm;
    },

    malpracticeInsurancePresenceCopy() {
      return this.possibleLawyer.lawyer.hasMalpracticeInsurancePolicy ? 'Yes' : 'No';
    },

    pricingTitle() {
      return this.lawFirm ? 'Law Firm Pricing' : 'Lawyer Pricing/Proposal';
    },

    featuredLawyersTitle() {
      return this.isAlsp ? 'Featured Professionals' : 'Featured Lawyers';
    },

    courtAdmissions() {
      return this.possibleLawyer.courtAdmissions;
    },

    isAlsp() {
      return this.lawFirm && this.lawFirm.alspAccount;
    },

    showProposalQuote() {
      return this.possibleLawyer.proposalQuote?.length && !this.lawFirm;
    },

    showFirmRate() {
      return this.possibleLawyer.firmRate?.length && this.isLawFirm;
    },

    isLawFirm() {
      return this.lawFirm && !this.lawFirm.alspAccount;
    },

    alspTechnologyTypes() {
      return this.lawFirm?.alspTechnologyAttributes?.types;
    },

    alspSecurityCertifications() {
      return this.lawFirm?.alspTechnologyAttributes?.securityCertifications;
    }
  },

  methods: {
    shouldShowSection(section) {
      return this.canShowSection(section) && this.sectionHasData(section);
    },

    canShowSection(section) {
      return this.possibleLawyer.displaySections.includes(section);
    },

    sectionHasData(section) {
      const dataPath = {
        bars:                  'barAdmissions',
        courts:                'courtAdmissions',
        education:             'schools',
        experience:            'yearsExperience',
        firm_featured_lawyers: 'featuredLawyers',
        languages:             'languages',
        location:              'lawyer.address',
        malpractice_insurance: 'lawyer.hasMalpracticeInsurancePolicy',
        notables:              'notableExperiences',
        proposal_bio:          'proposalBio',
        resume:                'resume.url'
      }[section];

      const data = get(this.possibleLawyer, dataPath);

      // We want to return true only if data is any of the following:
      // a Boolean, a Number, a-non empty String, a non empty array.
      return !isEmpty(data) || isNumber(data) || isBoolean(data);
    },

    barName(bar) {
      return bar.country === 'United States' ? bar.state : bar.country;
    },

    courtName(courtId) {
      return SharedListsService.courtName(courtId);
    },

    parseDate(date) {
      return DateFilter.filter(date, { year: 'numeric' });
    }
  }
};
</script>

<style scoped lang="scss">
  @import "stylesheets/globals/variables";

  .proposal-lawyer-details {
    @media (min-width: $screen-sm-min) {
      display: flex;
    }
  }

  .main-details {
    &.right-padded {
      @media (min-width: $screen-sm-min) {
        padding-right: 20px;
      }

      @media (min-width: $screen-md-min) {
        padding-right: 30px;
      }
    }
  }

  .lawyer-details-sidebar-container {
    @media (min-width: $screen-sm-min) {
      border-left: 1px solid $k-light-gray;
    }

    .lawyer-details-sidebar {
      border: 0;

      @media (min-width: $screen-sm-min) {
        padding: 0 20px;
      }

      @media (min-width: $screen-md-min) {
        padding: 0 30px;
      }
    }
  }

  .lawyer-details-sidebar-item {
    display: flex;
    column-gap: 15px;
    padding: 12px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
</style>
