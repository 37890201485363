<template>
  <div v-if="hasNote" class="lawyer-note">
    <div class="project-manager-row">
      <div class="hidden-xs">
        <svg-icon name="message" class="base-icon"></svg-icon>
      </div>

      <div class="manager-note">
        <div class="small-bold-header bottom-10">
          Note from project manager
        </div>

        <div>
          <span class="simple-text">{{ noteAboutLawyer }}</span>

          <div v-if="noteIsLong" class="top-10">
            <a href="" class="dark-gray-link" @click.prevent="toggleFullAdminNote">View {{ showFullAdminNote ? 'Less' : 'More' }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ProjectManagerNote',

  components: {
    SvgIcon
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      longTextCharcount: 250,
      showFullAdminNote: false
    };
  },

  computed: {
    hasNote() {
      return this.possibleLawyer.noteAboutLawyer && this.possibleLawyer.noteAboutLawyer.length > 0;
    },

    noteAboutLawyer() {
      return this.showFullAdminNote ? this.possibleLawyer.noteAboutLawyer : this.possibleLawyer.noteAboutLawyer.substring(0, this.longTextCharcount);
    },

    noteIsLong() {
      return this.possibleLawyer.noteAboutLawyer.length > this.longTextCharcount;
    }
  },

  watch: {
    possibleLawyer() {
      this.showFullAdminNote = false;
    }
  },

  methods: {
    toggleFullAdminNote() {
      this.showFullAdminNote = !this.showFullAdminNote;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .lawyer-note {
    padding: 15px 30px 15px 15px;
    background: $light-purple;
    border-radius: $border-radius-large;
  }

  .project-manager-row {
    align-items: flex-start;
    column-gap: 15px;
  }
</style>
