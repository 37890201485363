<template>
  <div :class="['profile-header', { 'top-aligned': lawFirm, 'narrow': outsideTheGate }]">
    <div v-if="!lawFirm">
      <profile-photo :user="proposalLawyer" :larger="isPreview"></profile-photo>
    </div>

    <div class="text-ellipsis">
      <div class="main-text text-ellipsis">
        {{ name }}
      </div>

      <div class="secondary-text">
        <div class="text-ellipsis" v-if="lawFirm && !isMarketplaceRfq">
          <span v-if="isAlsp">Law/Tech Company</span>
          <span v-else>{{ shouldShowSection('firm_size') && firmSize ? firmSize : 'Law Firm' }}</span>
        </div>

        <div class="text-ellipsis" v-if="lawFirm && isMarketplaceRfq">
          {{ shouldShowSection('firm_size') && firmSize ? firmSize : '' }}
        </div>

        <div class="text-ellipsis" v-if="lawFirm && shouldShowSection('firm_location')">
          {{ firmLocation }}
        </div>

        <div class="text-ellipsis" v-if="!lawFirm && isPreview">
          {{ currentPosition }}
        </div>

        <div class="text-ellipsis" v-if="!isMarketplaceRfq && shouldShowSection('location') && isPreview">
          {{ location }}
        </div>

        <div class="text-ellipsis" v-if="isMarketplaceRfq && isPreview && !lawFirm">
          {{ firmName }}
        </div>

        <div v-if="!isPreview" class="text-ellipsis">
          <a href="" @click.prevent="onAskQuestion">Send Message</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePhoto from 'vue-app/scout/shared/profile-photo.vue';

export default {
  name: 'ProposalLawyerBasicDetails',

  components: {
    ProfilePhoto
  },

  props: {
    possibleLawyer: {
      type: Object,
      default: () => ({})
    },

    lawyer: {
      type: Object,
      default:  () => ({})
    },

    isPreview: {
      type: Boolean,
      default: false
    },

    outsideTheGate: {
      type: Boolean,
      default: false
    },

    onAskQuestion: {
      type: Function,
      default: () => {}
    },

    isMarketplaceRfq: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    proposalLawyer() {
      return this.possibleLawyer.lawyer || this.lawyer;
    },

    lawFirm() {
      return this.possibleLawyer.lawFirm;
    },

    name() {
      return this.lawFirm?.name || this.proposalLawyer.fullName;
    },

    location() {
      return this.proposalLawyer.address?.city;
    },

    firmLocation() {
      return this.possibleLawyer.firmLocation;
    },

    firmSize() {
      return this.possibleLawyer.firmSize;
    },

    firmName() {
      return this.proposalLawyer.lawFirmName;
    },

    isAlsp() {
      return this.lawFirm?.alspAccount;
    },

    currentPosition() {
      return this.proposalLawyer.currentPosition;
    }
  },

  methods: {
    shouldShowSection(section) {
      return this.possibleLawyer?.displaySections?.indexOf(section) >= 0;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .profile-header {
    display: flex;
    flex: 1;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 30px;

    @media (min-width: $screen-sm-min) {
      margin-bottom: 0;
    }

    &.top-aligned {
      align-items: flex-start;
    }

    &.narrow {
      @media (min-width: $screen-sm-min) {
        min-width: 55%;
        max-width: 55%;
      }

      @media (min-width: $screen-md-min) {
        min-width: 60%;
        max-width: 60%;
      }
    }

    .main-text {
      font-size: 18px;
      font-weight: 600;
      text-align: left;

      @media (min-width: $screen-sm-min) {
        font-size: 20px;
      }
    }

    .secondary-text {
      text-align: left;
    }
  }

  .profile-header :deep(.user-pic).larger-pic {
    @media (max-width: $screen-xs-max) {
      width: 60px;
      height: 60px;
    }
  }
</style>
